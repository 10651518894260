import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import AWSwitch from "@aviwest/ui-kit/dist/js/components/switch";

import {
  outputRtspLocalURIs, outputRtspPublicURI, outputRtspPushURI
} from "../../../../misc/streamhub.adapters";
import {containsUnderscore, isEmptyString, isFullURLValid, isGroupValid, isRTSPValid} from "../../../../utils/string-utils";
import {RTSP} from "../../../../constants";
import PasswordRevealInput from "../../../common/password-reveal-input";
import HelpLayout from "../../../common/help-layout";
import { isPortInRange } from "../../../../utils/global-utils";

const propTypes = {
  additionalActions: PropTypes.node,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    login: PropTypes.string,
    password: PropTypes.string,
    serverMode: PropTypes.bool,
    uri: PropTypes.string,
    streamKey: PropTypes.string,
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.object).isRequired,
  forbiddenTCPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSubmit: PropTypes.func.isRequired,
  localIps: PropTypes.arrayOf(PropTypes.string),
  publicIp: PropTypes.string,
  publicHostname: PropTypes.string,
  rtspServer: PropTypes.shape({
    port: PropTypes.number.isRequired
  }).isRequired,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const RTSPForm = (props) => {
  const { config, forbiddenNames, forbiddenUris, localIps, publicIp, publicHostname, rtspServer, translate, alert, protocol } = props;
  const [passwordProtected, setPasswordProtected] = useState(config && config.password ? true : false)

  const uriList = (values) => {
    let uris = null;
    if(values.serverMode){
      uris = outputRtspLocalURIs(localIps, values);
      if (publicIp) {
        uris.push(outputRtspPublicURI(publicIp, values));
      }
      if (publicHostname) {
        uris.push(outputRtspPublicURI(publicHostname, values));
      }
    } else {
      uris = [outputRtspPushURI(values)];
    }
    return uris;
  };

  const handleFormSubmit = (values) => {
    const data = {
      ...values
    };

    // Reset some properties in 'server' mode
    if(values.serverMode) {
      data['uri'] = null;
      data['streamKey'] = null;
    }
    else {
      data['label'] = null;
    }
    props.onSubmit(data);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    else if(!isGroupValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text'
    }
    if (isEmptyString(values.login) && !isEmptyString(values.password)) {
      errors.login = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (!isEmptyString(values.login) && isEmptyString(values.password)) {
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    } else {
      delete errors.password;
    }

    // Password with length > 32 characters are not encrypted (Bugs #15905)
    if((!config || (config && config.password !== values.password)) && values.password.length > 32) {
      errors.password = 'genericLabel.TOO_LONG.text';
    }

    if(!values.serverMode){
      const concat =  values.uri+'/'+values.streamKey;
      if (!(isFullURLValid(values.uri) || isRTSPValid(values.uri))){
        errors.uri = 'genericLabel.INVALID_FORMAT.text';
      }
      if(isEmptyString(values.uri)){
        errors.uri = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.streamKey)){
        errors.streamKey = 'genericLabel.REQUIRED_FIELD.text';
      }
      
      if (forbiddenUris.filter( element => element.uri === concat).length !== 0) {
        errors.uri = 'genericLabel.DUPLICATED_VALUES_FROM.text';
        errors.streamKey = 'genericLabel.DUPLICATED_VALUES_FROM.text';
      }

    } else {
      if(isEmptyString(values.label)){
        errors.label = 'genericLabel.REQUIRED_FIELD.text';
      }

      if(containsUnderscore(values.label)){
        errors.label = 'genericLabel.INVALID_FORMAT.text';
      }

      //TCP port
    const forbiddenManagerPorts = props.forbiddenTCPPorts;
    if(!config || (config && values.port !== config.port)){
      console.log("before2");
      if(isEmptyString(values.port)){
        errors.port = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(!isPortInRange(values.port, 1024, 65535)){
        errors.port = 'genericLabel.MANAGER_PORT_HELP.text';
      }
      else if(forbiddenManagerPorts.indexOf(values.port) !== -1){
        errors.port = 'genericLabel.PORT_ALREADY_USED.text';
      }
    }
    }

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.filter( element => element.uri === uri).length !== 0) !== undefined){
      errors.duplicateUriFrom = forbiddenUris.find(element => uris.filter(uri => uri === element.uri).length !== 0).name
      errors.label = 'genericLabel.DUPLICATED_VALUES_FROM.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              mode: RTSP,
              name: config ? config.name : '',
              label: config ? config.label : '',
              login: config && config.login ? config.login : '',
              password: config && config.password ? config.password : '',
              port: config && config.port ? config.port : rtspServer.port,
              serverMode: config ? (config.serverMode ?? true) : false,
              uri: config && config.uri ? config.uri : '',
              streamKey: config && config.streamKey ? config.streamKey : ''
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_rtsp_output_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="outputsProfile_rtsp_name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="serverMode">
                <Translate id="genericLabel.SERVER_MODE.text"/>
              </Label>
              <div className="push-pull"
                  id="outputsProfile_rtsp_serverMode" >
                <div className="push">
                  <Translate id="genericLabel.PUSH.text"></Translate>
                </div>
                <AWSwitch checked={values.serverMode}
                          onChange={(checked) => setFieldValue('serverMode', !values.serverMode)}/>
                <div className="pull">
                  <Translate id="genericLabel.PULL.text"></Translate>
                </div>
              </div>
            </FormGroup>

            { values.serverMode === true &&
            <>
              <FormGroup>
                <Label for="label">
                  <Translate id="genericLabel.LABEL.text"/>
                </Label>
                <Input type="text"
                      name="label"
                      id="outputsProfile_rtsp_pull_label"
                      invalid={errors.label !== undefined}
                      placeholder={ translate('genericLabel.LABEL.text') }
                      value={values.label}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.label} /> { errors.duplicateUriFrom }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="port">
                  Port
                </Label>
                <Input type="number"
                      name="port"
                      id="outputsProfile_rtsp_pull_port"
                      invalid={errors.port !== undefined}
                      placeholder={ translate('genericLabel.PORT.text') }
                      value={values.port}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.port} />
                </FormFeedback>
                <div className="indicator">
                  <Translate id="genericLabel.MANAGER_PORT_HELP.text" />
                </div>
            </FormGroup>
          </>
            }
            { values.serverMode === false &&
            <Fragment>
              <FormGroup>
                <Label for="uri">
                  <Translate id="genericLabel.STREAM_URL.text"/>
                </Label>
                <Input type="text"
                      name="uri"
                      id="outputsProfile_rtsp_push_uri"
                      invalid={errors.uri !== undefined}
                      placeholder={ translate('genericLabel.STREAM_URL.text') }
                      value={values.uri}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.uri} /> { errors.duplicateUriFrom }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="streamKey">
                  <Translate id="genericLabel.STREAM_KEY.text"/>
                </Label>
                <Input type="text"
                      name="streamKey"
                      id="outputsProfile_rtsp_push_streamKey"
                      invalid={errors.streamKey !== undefined}
                      placeholder={ translate('genericLabel.STREAM_KEY.text') }
                      value={values.streamKey}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.streamKey} /> { errors.duplicateUriFrom }
                </FormFeedback>
              </FormGroup>

            </Fragment>
            }
              <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="passwordProtected"
                      id="outputsProfile_rtsp_push_passwordProtected"
                      onChange={() => {
                        setPasswordProtected(!passwordProtected);
                        setFieldValue('login', '');
                        setFieldValue('password', '');
                      }}
                      checked={passwordProtected}/>{' '}
                <Translate id="genericLabel.PASSWORD_PROTECTED.text"/>
              </Label>
              </FormGroup>
              { passwordProtected &&
              <>
                <FormGroup>
                  <Label for="login">
                    <Translate id="genericLabel.LOGIN.text"/>
                  </Label>
                  <Input type="text"
                        name="login"
                        id="outputsProfile_rtsp_login"
                        invalid={errors.login !== undefined}
                        placeholder={ translate('genericLabel.LOGIN.text') }
                        value={values.login}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.login} />
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="password">
                    <Translate id="genericLabel.PASSWORD.text"/>
                  </Label>
                  <PasswordRevealInput name="password"
                                      id="outputsProfile_rtsp_password"
                                      setFieldValue={setFieldValue}
                                      invalid={errors.password !== undefined}
                                      placeholder={ translate('genericLabel.PASSWORD.text') }
                                      value={values.password}
                                      onChange={handleChange}
                                      error={errors.password}/>
                </FormGroup>
              </>
              }


            <div className="uri-preview">
              { uriList(values).map((uri, index) => (<div key={`${uri}-${index}`} className="uri" id={"outputsProfile_rtsp_uri_"+index}>{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.additionalActions }
              <Button id="outputsProfile_rtsp_saveButon"
                      color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />

        </Form>
      )}
    </Formik>
  );
};

RTSPForm.propTypes = propTypes;

export default withLocalize(RTSPForm);
